@charset "UTF-8";
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}
/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 13px;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}
/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/*
    == BASE : Define <html>, <body> and selection styles ==
*/
html {
  font-size: 62.5%;
  font-size: calc(1em*0.625);
  overflow-x: hidden;
}
html.opened-modal,
body.opened-modal,
html.opened-menu,
body.opened-menu {
  overflow: hidden !important;
}
body {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #3a3a3a;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  line-height: 1.5;
  /* Box-sizing */
}
@media screen and (min-width: 360px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 768px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 990px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 1200px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
body *,
body *:before,
body *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
div,
dl,
dd,
dt,
p,
ul,
li,
ol {
  padding: 0;
  margin: 0;
  border: 0;
}
li {
  list-style: none;
}
/* define text selection */
::selection {
  background: #3a3a3a;
  text-shadow: none;
  color: white;
}
/*
  * Extend the ChuckCSS settings.less file *
  *** OVERRIDE CHUCKCSS VARS ***
  * Specific vars
*/
/*
    -- @ Colors @ --
*/
/* Primary color */
/*
    -- @ Titles @ --
*/
@media screen and (min-width: 990px) {
  html.opened-menu,
  body.opened-menu {
    overflow-y: auto !important;
  }
}
body {
  background-color: white;
  color: #3a3a3a;
  font-family: 'Open Sans', sans-serif;
  font-display: auto;
}
body .cc-inside {
  width: 100%;
  margin: 0 auto;
  -webkit-transition: 0.2s width ease-out 0s;
  transition: 0.2s width ease-out 0s;
}
@media screen and (min-width: 990px) {
  body .cc-inside {
    width: 900px;
    margin: 0 auto;
    -webkit-transition: 0.2s width ease-out 0s;
    transition: 0.2s width ease-out 0s;
  }
}
@media screen and (min-width: 1200px) {
  body .cc-inside {
    width: 1100px;
    margin: 0 auto;
    -webkit-transition: 0.2s width ease-out 0s;
    transition: 0.2s width ease-out 0s;
  }
}
body ul {
  color: #3a3a3a;
  padding: 0;
  margin: 0;
  border: 0;
}
body ul li {
  list-style: none;
}
/* define text selection */
::selection {
  background: #ff810d;
  color: white;
}
/* ***************** */
/* *********************************************************************************************** */
/** BELOW, SPECIFIC CHUCKCSS USED ON HOMEPAGE BUT DELETED FROM IMPORT IN TOP PAGE, ONLY FOR OPTIM **/
/* *********************************************************************************************** */
/* ***************** */
/* ***************** */
/** LINKS / BUTTONS **/
/* ***************** */
a {
  text-decoration: none;
  color: #3a3a3a;
}
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}
/* Buttons && buttons-like HTML links */
a.btn,
button {
  display: inline-block;
  color: #3a3a3a;
  padding: 0.3em 1em;
  line-height: 1.8;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 1.6rem;
  vertical-align: middle;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
  /* hover : default, change border-color */
}
a.btn:focus,
button:focus,
a.btn:active,
button:active,
a.btn:visited,
button:visited {
  outline: none;
}
a.btn:hover,
button:hover {
  border-color: #f9f9f9;
}
i {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
i.fa-accessible-icon:before {
  content: "\F368";
}
i.fa-angle-double-left:before {
  content: "\F100";
}
i.fa-angle-double-right:before {
  content: "\F101";
}
i.fa-assistive-listening-systems:before {
  content: "\F2A2";
}
i.fa-circle:before {
  content: "\F111";
}
i.fa-cloud-upload:before {
  content: "\F0EE";
}
i.fa-code:before {
  content: "\F121";
}
i.fa-comment-smile:before {
  content: "\F4B4";
}
i.fa-drafting-compass:before {
  content: "\F568";
}
i.fa-envelope:before {
  content: "\F0E0";
}
i.fa-envelope-open:before {
  content: "\F2B6";
}
i.fa-graduation-cap:before {
  content: "\F19D";
}
i.fa-hands-helping:before {
  content: "\F4C4";
}
i.fa-keyboard:before {
  content: "\F11C";
}
i.fa-megaphone:before {
  content: "\F675";
}
i.fa-parking:before {
  content: "\F540";
}
i.icon-left {
  margin-right: 8px;
}
i.icon-right {
  margin-left: 8px;
}
h1 {
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 1.86666667em;
  line-height: 1.5;
  font-weight: 400;
  color: #070707;
  margin: 0;
}
h3 {
  margin: 0;
  font-size: 1.66666667em;
  line-height: 1.5;
  font-weight: 400;
  color: #070707;
}
h4 {
  margin: 0;
  font-size: 1.33333333em;
  line-height: 1.5;
  font-weight: 400;
  color: #070707;
}
/* ******** */
/** MODALS **/
/* ******** */
/* ********************* */
/* Modal wrapper */
.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: -2;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0;
  /* Shown modal */
  /* Sizes */
  /* Close button */
  /* Content */
  /* Overlay */
}
.modal.active {
  opacity: 1;
  z-index: 99;
}
.modal.active .modal-content {
  opacity: 1;
  /* X != Y */
  /* X only */
  -webkit-transform: scale(1);
          transform: scale(1);
  /* X & Y empty : default value */
}
.modal.active .modal-close,
.modal.active > [data-close-modal] {
  -webkit-transition: 0.3s top ease-out 0.3s;
  transition: 0.3s top ease-out 0.3s;
  top: 8px;
}
@media screen and (min-width: 768px) {
  .modal.active .modal-close,
  .modal.active > [data-close-modal] {
    top: 20px;
  }
}
.modal.active .modal-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
@media screen and (min-width: 768px) {
  .modal.modal-large .modal-content {
    width: 80%;
  }
}
.modal > [data-close-modal] {
  padding: 0;
  margin: 0;
  border: 0;
  position: fixed;
  top: -60px;
  left: 50%;
  z-index: 2;
  background: none;
  border-radius: 100%;
  line-height: inherit;
  background-color: transparent;
  width: 40px;
  height: 40px;
  margin-left: -20px;
}
.modal > [data-close-modal]:after,
.modal > [data-close-modal]:before {
  display: inline-block;
  content: ' ';
  width: 19px;
  height: 3px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  top: 19px;
  left: 10px;
}
.modal > [data-close-modal]:before {
  /* Not perspective */
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  /* Perspective */
}
.modal > [data-close-modal]:after {
  /* Not perspective */
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  /* Perspective */
}
.modal > [data-close-modal]:hover {
  background-color: #ef6a6a;
}
.modal-content {
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  z-index: 2;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
  /* X != Y */
  /* X only */
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  /* X & Y empty : default value */
}
.modal-content .modal-body {
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .modal-content {
    width: 50%;
  }
}
.modal-overlay {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
/*
    == HELPERS ==
*/
.cc-inside {
  width: 1199px;
  margin: 0 auto;
  -webkit-transition: 0.2s width ease-out 0s;
  transition: 0.2s width ease-out 0s;
}
.cc-mat-40 {
  margin-top: 40px;
}
.cc-mab-50 {
  margin-bottom: 50px;
}
.cc-fs-30 {
  font-size: 3rem;
}
.cc-txt-center {
  text-align: center;
}
/*
    == COLORS ==
*/
p.cc-green {
  color: #5c7d2b;
}
span.cc-bg-green {
  color: #fff;
  background-color: #5c7d2b;
}
span.cc-bg-red {
  color: #fff;
  background-color: #c41515;
}
h2.cc-bg-gray,
div.cc-bg-gray,
section.cc-bg-gray {
  color: #fff;
  background-color: #f9f9f9;
}
a.cc-bg-gray a:not(.btn),
article.cc-bg-gray a:not(.btn),
aside.cc-bg-gray a:not(.btn),
code.cc-bg-gray a:not(.btn),
div.cc-bg-gray a:not(.btn),
em.cc-bg-gray a:not(.btn),
footer.cc-bg-gray a:not(.btn),
header.cc-bg-gray a:not(.btn),
i.cc-bg-gray a:not(.btn),
main.cc-bg-gray a:not(.btn),
nav.cc-bg-gray a:not(.btn),
p.cc-bg-gray a:not(.btn),
section.cc-bg-gray a:not(.btn),
small.cc-bg-gray a:not(.btn),
span.cc-bg-gray a:not(.btn),
strong.cc-bg-gray a:not(.btn),
td.cc-bg-gray a:not(.btn),
th.cc-bg-gray a:not(.btn) {
  color: #fff;
}
span.cc-bg-blue {
  color: #fff;
  background-color: #3498db;
}
a.btn.cc-bg-orange {
  color: black;
  background-color: #ff810d;
  border-color: #ff810d;
}
strong {
  font-weight: 600;
}
h1,
.h1-like {
  font-family: 'Titillium Web', sans-serif;
  font-display: auto;
  color: #444444;
}
.h2-main-title {
  padding: 50px 20px;
  color: #3a3a3a;
  letter-spacing: 2px;
  font-weight: 300;
  text-align: center;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .h2-main-title {
    font-size: 2.8rem;
  }
}
@media screen and (min-width: 990px) {
  .h2-main-title {
    padding-left: 0;
    padding-right: 0;
  }
}
/*
  - Videos top of pages
*/
.css-video-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.homepage .css-video-wrapper {
  height: 50vh;
}
.css-video-wrapper .video-overlay {
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 768px) {
  .css-video-wrapper {
    height: 45vh;
  }
  .homepage .css-video-wrapper {
    height: 100vh;
  }
}
/*
  - Titles H1 + Intro text on pages
*/
.page-intro {
  padding: 50px 20px;
}
.page-intro h1 {
  font-weight: 600;
  font-size: 2.5rem;
}
.page-intro .intro {
  font-weight: 300;
  font-size: 1.9rem;
  text-align: justify;
}
@media screen and (min-width: 768px) {
  .page-intro {
    padding: 120px 20px 60px;
  }
  .page-intro h1 {
    font-size: 3.9rem;
  }
  .page-intro .intro {
    text-align: center;
    line-height: 1.8;
  }
}
/*
  - 3 pushes after intro
  * Used in :
    - Homepage
    - Metiers & Valeurs
*/
.pushes {
  padding: 50px 20px;
}
.pushes div {
  padding: 30px 0;
  text-align: center;
}
.pushes div a {
  display: block;
}
.pushes div h2 {
  margin: 20px 0;
}
.pushes div .push-description {
  font-style: italic;
  color: #999999;
  font-size: 1.4rem;
}
.pushes div .push-description strong {
  color: #ff810d;
}
.pushes div .link-preston {
  display: block;
  width: 150px;
  height: 150px;
  background-color: #93ddea;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.pushes div .link-preston img,
.pushes div .link-preston picture {
  position: relative;
  display: block;
}
.pushes div .link-preston img.hide,
.pushes div .link-preston picture.hide {
  display: none;
}
@media screen and (min-width: 768px) {
  .pushes {
    padding: 60px 20px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .pushes div {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0 30px;
  }
}
/*
  - Realisations pushes
  * Used in :
    - Homepage
    - Clients
*/
.realisations article {
  min-height: 250px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin-bottom: 5px;
  overflow: hidden;
}
.realisations article a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  position: relative;
}
.agency .realisations article a:after {
  display: none;
}
.realisations article a:after {
  display: block;
  content: ' ';
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: -1;
}
.realisations article a h3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  font-family: 'Titillium Web', sans-serif;
  font-display: auto;
}
.realisations article a h3:after {
  display: block;
  content: ' ';
  background-color: currentColor;
  height: 1px;
  width: 30%;
  margin: 15px auto 20px;
}
.realisations article a h4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  color: #737373;
  font-weight: 300;
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  .realisations article {
    min-height: 350px;
  }
}
@media screen and (min-width: 990px) {
  .realisations {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .realisations .realisation-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    /*
        C'est joli et ça me sert pour l'inté, donc on ne supprime pas merki :)

        +-----------+-----+-----+
        |           | 2   | 3   |
        +     1     +-----+-----+
        |           |     4     |
        +-----------+-----+-----+
        |     5     | 6   | 7   |
        +-----+-----+-----+-----+
        | 8   | 9   |           |
        +-----+-----+    11     +
        |    10     |           |
        +-----+-----+-----+-----+
        |    12     | 13  | 14  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 16  | 17  |
        +    15     +-----+-----+
        |           |     18    |
        +-----------+-----+-----+
        |    19     | 20  | 21  |
        +-----+-----+-----+-----+
        | 22  | 23  |           |
        +-----+-----+    25     +
        |    24     |           |
        +-----+-----+-----+-----+
        |    26     | 27  | 28  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 30  | 31  |
        +    29     +-----+-----+
        |           |     32    |
        +-----------+-----+-----+
        |    33     | 34  | 35  |
        +-----+-----+-----+-----+
        | 36  | 37  |           |
        +-----+-----+    39     +
        |    38     |           |
        +-----+-----+-----+-----+
        |    40     | 41  | 42  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 44  | 45  |
        +    43     +-----+-----+
        |           |     46    |
        +-----------+-----+-----+
        |    47     | 48  | 49  |
        +-----+-----+-----+-----+
        | 50  | 51  |           |
        +-----+-----+    53     +
        |    52     |           |
        +-----+-----+-----+-----+
        |    54     | 55  | 56  |
        +-----------+-----+-----+
    */
    /* Big Images */
    /* Small : 50% width */
  }
  .realisations .realisation-item article {
    margin: 0.5%;
    min-height: 300px;
  }
  .realisations .realisation-item article:hover a {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .realisations .realisation-item article:hover .backstretch img {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
  }
  .realisations .realisation-item article a {
    opacity: 0;
    -webkit-transform: translate3d(0px, 250px, 0px);
            transform: translate3d(0px, 250px, 0px);
    -webkit-transition: 0.35s all ease-out 0s;
    transition: 0.35s all ease-out 0s;
    -webkit-transition-timing-function: cubic-bezier(0.45, 0.38, 0.34, 0.83);
            transition-timing-function: cubic-bezier(0.45, 0.38, 0.34, 0.83);
  }
  .realisations .realisation-item article .backstretch img {
    -webkit-transition: 0.2s -webkit-filter ease-out 0s;
    transition: 0.2s -webkit-filter ease-out 0s;
    transition: 0.2s filter ease-out 0s;
    transition: 0.2s filter ease-out 0s, 0.2s -webkit-filter ease-out 0s;
    -webkit-filter: grayscale(0.8);
            filter: grayscale(0.8);
  }
  .realisations .realisation-item.item-53,
  .realisations .realisation-item.item-43,
  .realisations .realisation-item.item-39,
  .realisations .realisation-item.item-29,
  .realisations .realisation-item.item-25,
  .realisations .realisation-item.item-15,
  .realisations .realisation-item.item-11,
  .realisations .realisation-item.item-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .realisations .realisation-item.item-53 article,
  .realisations .realisation-item.item-43 article,
  .realisations .realisation-item.item-39 article,
  .realisations .realisation-item.item-29 article,
  .realisations .realisation-item.item-25 article,
  .realisations .realisation-item.item-15 article,
  .realisations .realisation-item.item-11 article,
  .realisations .realisation-item.item-1 article {
    width: 99%;
  }
  .realisations .realisation-item.item-55,
  .realisations .realisation-item.item-50,
  .realisations .realisation-item.item-48,
  .realisations .realisation-item.item-44,
  .realisations .realisation-item.item-41,
  .realisations .realisation-item.item-36,
  .realisations .realisation-item.item-34,
  .realisations .realisation-item.item-30,
  .realisations .realisation-item.item-27,
  .realisations .realisation-item.item-22,
  .realisations .realisation-item.item-20,
  .realisations .realisation-item.item-16,
  .realisations .realisation-item.item-13,
  .realisations .realisation-item.item-8,
  .realisations .realisation-item.item-6,
  .realisations .realisation-item.item-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .realisations .realisation-item.item-55 article,
  .realisations .realisation-item.item-50 article,
  .realisations .realisation-item.item-48 article,
  .realisations .realisation-item.item-44 article,
  .realisations .realisation-item.item-41 article,
  .realisations .realisation-item.item-36 article,
  .realisations .realisation-item.item-34 article,
  .realisations .realisation-item.item-30 article,
  .realisations .realisation-item.item-27 article,
  .realisations .realisation-item.item-22 article,
  .realisations .realisation-item.item-20 article,
  .realisations .realisation-item.item-16 article,
  .realisations .realisation-item.item-13 article,
  .realisations .realisation-item.item-8 article,
  .realisations .realisation-item.item-6 article,
  .realisations .realisation-item.item-2 article {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 49%;
        -ms-flex: 0 0 49%;
            flex: 0 0 49%;
  }
  .realisations .realisation-item.item-55 article:nth-child(3),
  .realisations .realisation-item.item-50 article:nth-child(3),
  .realisations .realisation-item.item-48 article:nth-child(3),
  .realisations .realisation-item.item-44 article:nth-child(3),
  .realisations .realisation-item.item-41 article:nth-child(3),
  .realisations .realisation-item.item-36 article:nth-child(3),
  .realisations .realisation-item.item-34 article:nth-child(3),
  .realisations .realisation-item.item-30 article:nth-child(3),
  .realisations .realisation-item.item-27 article:nth-child(3),
  .realisations .realisation-item.item-22 article:nth-child(3),
  .realisations .realisation-item.item-20 article:nth-child(3),
  .realisations .realisation-item.item-16 article:nth-child(3),
  .realisations .realisation-item.item-13 article:nth-child(3),
  .realisations .realisation-item.item-8 article:nth-child(3),
  .realisations .realisation-item.item-6 article:nth-child(3),
  .realisations .realisation-item.item-2 article:nth-child(3) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 99%;
        -ms-flex: 0 0 99%;
            flex: 0 0 99%;
  }
}
/* ********* */
/** IMPORTS **/
/* ********* */
/* Import Helpers files */
/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  border-radius: 0px;
}
.hamburger:hover {
  background-color: transparent;
  border: 0;
}
.hamburger-box {
  width: 24px;
  height: 20px;
  display: inline-block;
  position: relative;
}
.hamburger-box .hamburger-inner {
  display: block;
  top: 0px;
  width: 100%;
  height: 2px;
  background-color: #ff810d;
  position: absolute;
  -webkit-transition: 0.15s all ease 0s;
  transition: 0.15s all ease 0s;
}
.hamburger-box .hamburger-inner::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ff810d;
  position: absolute;
  -webkit-transition: 0.15s all ease 0s;
  transition: 0.15s all ease 0s;
  width: 75%;
  top: 7px;
}
.hamburger-box .hamburger-inner::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ff810d;
  position: absolute;
  -webkit-transition: 0.15s all ease 0s;
  transition: 0.15s all ease 0s;
  width: 50%;
  top: 14px;
}
/*
 * Elastic
 */
.hamburger--elastic {
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic:before {
  -webkit-transition: opacity 0.15s 0.4s ease;
  transition: opacity 0.15s 0.4s ease;
}
.hamburger--elastic:after {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 6px, 0) rotate(135deg);
          transform: translate3d(0, 6px, 0) rotate(135deg);
}
.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner:after {
  width: 100%;
  -webkit-transform: translate3d(0, -14px, 0) rotate(-270deg);
          transform: translate3d(0, -14px, 0) rotate(-270deg);
}
/* ********* */
/** MODALS **/
/* ********* */
/* ********************* */
#modal-contact .modal-body {
  padding: 0;
}
#modal-contact .modal-body .wrap-modal-content {
  padding: 20px;
  text-align: center;
}
#modal-contact .modal-body .wrap-modal-content div {
  padding: 15px 20px 40px;
}
#modal-contact .modal-body .wrap-modal-content div .icon span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto 20px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 3rem;
  border-radius: 100%;
}
#modal-contact .modal-body .wrap-modal-content div .icon span i {
  padding: 0;
  margin: 0;
  border: 0;
  display: block;
}
#modal-contact .modal-body .wrap-modal-content div strong {
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#modal-contact .modal-body .wrap-modal-content div strong small {
  color: #575757;
  font-weight: 300;
  text-transform: none;
  display: block;
}
#modal-contact .modal-body .wrap-modal-content div a {
  color: #B85500;
  font-weight: 600;
  font-size: 1.6rem;
}
@media screen and (min-width: 990px) {
  #modal-contact .modal-body h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  #modal-contact .modal-body .wrap-modal-content {
    padding: 40px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  #modal-contact .modal-body .wrap-modal-content div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
  }
}
/* Import libraries plugins files */
/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-enabled .flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-enabled .flickity-viewport .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -35px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
          animation-name: flipInX;
}
@-webkit-keyframes slideInDownBig {
  from {
    -webkit-transform: translate3d(0, -45px, 0);
            transform: translate3d(0, -45px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDownBig {
  from {
    -webkit-transform: translate3d(0, -45px, 0);
            transform: translate3d(0, -45px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.slideInDownBig {
  -webkit-animation-name: slideInDownBig;
          animation-name: slideInDownBig;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.08, 1.08, 1.08);
            transform: scale3d(1.08, 1.08, 1.08);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.08, 1.08, 1.08);
            transform: scale3d(1.08, 1.08, 1.08);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
}
@-webkit-keyframes wrench {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes wrench {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.wrench {
  -webkit-transform-origin: 56px 15px;
          transform-origin: 56px 15px;
  -webkit-animation-name: wrench;
          animation-name: wrench;
}
@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  6.5% {
    -webkit-transform: translateY(-6px) rotateY(-9deg);
            transform: translateY(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateY(5px) rotateY(7deg);
            transform: translateY(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateY(-3px) rotateY(-5deg);
            transform: translateY(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateY(2px) rotateY(3deg);
            transform: translateY(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  6.5% {
    -webkit-transform: translateY(-6px) rotateY(-9deg);
            transform: translateY(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateY(5px) rotateY(7deg);
            transform: translateY(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateY(-3px) rotateY(-5deg);
            transform: translateY(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateY(2px) rotateY(3deg);
            transform: translateY(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
          animation-name: headShake;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@media (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
            animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
@-webkit-keyframes hinge {
  0%,
  6%,
  12% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  3%,
  9% {
    -webkit-transform: translate3d(0, 3px, 0);
            transform: translate3d(0, 3px, 0);
  }
  40%,
  44%,
  48%,
  52% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  42%,
  46%,
  50% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
            transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0%,
  6%,
  12% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  3%,
  9% {
    -webkit-transform: translate3d(0, 3px, 0);
            transform: translate3d(0, 3px, 0);
  }
  40%,
  44%,
  48%,
  52% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  42%,
  46%,
  50% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
            transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
          animation-name: hinge;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-transform-origin: 30px 30px;
          transform-origin: 30px 30px;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, -140px, 0);
            transform: translate3d(0, -140px, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, -140px, 0);
            transform: translate3d(0, -140px, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
/*
  @@ LOZAD animations @@
  -- Animate img and backstretch when elements are loaded with lozad
*/
img[data-loaded="true"],
[data-loaded="true"] img {
  -webkit-animation-name: showLazyImg;
          animation-name: showLazyImg;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes showLazyImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes showLazyImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-container {
  overflow: hidden;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}
.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  -webkit-filter: inherit;
          filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}
.leaflet-pane {
  z-index: 400;
}
.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}
.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}
.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}
/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  right: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}
/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  transition: none;
}
.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}
/* cursors */
.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}
.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078A8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}
/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}
/* general toolbar styles */
.leaflet-bar {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}
.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}
.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}
/* layers control */
.leaflet-control-layers {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  background-image: url(/images/leaflet/layers.png);
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/images/leaflet/layers-2x.png);
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}
/* Default icon URLs/ */
.leaflet-default-icon-path {
  background-image: url(/images/leaflet/marker-icon.png);
}
/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 13px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}
.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  -webkit-box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
          box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}
.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}
/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}
/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}
/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}
/* Import Website styles */
#main-header {
  padding: 15px;
  position: relative;
  -webkit-transition: 0.2s background-color ease-out 0s;
  transition: 0.2s background-color ease-out 0s;
}
#main-header.sticky {
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.05);
          box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.05);
}
#main-header #logo {
  display: block;
  padding-left: 5px;
  max-width: 65%;
}
#main-header #logo img {
  vertical-align: bottom;
}
#main-header .css-open-menu {
  position: absolute;
  z-index: 8;
  top: 13px;
  right: 0;
}
#main-header nav {
  position: fixed;
  height: 100vh;
  z-index: 98;
  background-color: white;
  top: 0;
  left: -120%;
  width: 100%;
  bottom: 0;
  padding-top: 45px;
  overflow-y: auto;
  -webkit-transition: 0.35s left cubic-bezier(1, 0, 0, 1) 0s;
  transition: 0.35s left cubic-bezier(1, 0, 0, 1) 0s;
}
#main-header nav.shown {
  left: 0%;
}
#main-header nav > ul > li {
  text-align: center;
  border-bottom: 1px solid #ececec;
  position: relative;
}
#main-header nav > ul > li:nth-child(1) {
  border-top: 1px solid #ececec;
}
#main-header nav > ul > li a {
  text-transform: uppercase;
  display: block;
  padding: 10px;
  font-size: 1.8rem;
  font-weight: 300;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
#main-header nav > ul > li a:hover,
#main-header nav > ul > li a.active {
  color: #ff810d;
}
#main-header nav > ul > li ul {
  margin-bottom: 10px;
}
#main-header nav > ul > li ul li a {
  text-transform: none;
  font-size: 1.4rem;
  padding: 5px;
}
#main-header .header-contact {
  padding: 15px 0 0;
}
#main-header .header-contact div {
  padding: 5px;
}
#main-header .header-contact div a {
  display: block;
  width: 100%;
  position: relative;
  background-color: #282828;
  color: white;
  border: 0;
  font-size: 1.4rem;
}
#main-header .header-contact div a p {
  position: relative;
  z-index: 2;
}
#main-header .header-contact div a:hover:after {
  width: 100%;
}
#main-header .header-contact div a:after {
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  border-radius: 3px;
  background-color: #0e0e0e;
  z-index: 1;
  -webkit-transition: 0.35s width cubic-bezier(1, 0, 0, 1) 0s;
  transition: 0.35s width cubic-bezier(1, 0, 0, 1) 0s;
}
@media screen and (min-width: 360px) {
  #main-header .header-contact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #main-header .header-contact div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}
@media screen and (min-width: 768px) {
  #main-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    z-index: 98;
    left: 0;
    right: 0;
  }
  body:not(.homepage) #main-header {
    background-color: rgba(255, 255, 255, 0.95);
  }
  #main-header.sticky {
    padding: 0 15px;
  }
  #main-header.sticky .header-contact {
    display: block;
  }
  #main-header #logo {
    padding: 15px 30px;
    max-width: 250px;
  }
  #main-header .css-open-menu {
    display: none;
  }
  #main-header nav {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    position: static;
    height: inherit;
    background-color: transparent;
    padding-top: 0px;
    padding-right: 15px;
    overflow-y: inherit;
  }
  #main-header nav > ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  #main-header nav > ul > li {
    border: 0;
  }
  #main-header nav > ul > li:nth-child(1) {
    border-top-width: 0px;
  }
  #main-header nav > ul > li:hover > a {
    color: #ff810d;
  }
  #main-header nav > ul > li:hover ul {
    display: block;
  }
  #main-header nav > ul > li a {
    font-size: 1.5rem;
  }
  #main-header nav > ul > li a.active,
  #main-header nav > ul > li a:hover {
    color: #ff810d;
  }
  #main-header nav > ul > li ul {
    display: none;
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 100%;
    margin-bottom: 0;
    background-color: white;
    padding: 10px 0;
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
  }
  #main-header nav > ul > li ul li a {
    padding: 5px 15px;
  }
  #main-header .header-contact {
    padding: 0;
    display: block;
    padding: 15px;
    display: none;
  }
  #main-header .header-contact div {
    padding: 0;
  }
  #main-header .header-contact div:nth-child(1) {
    padding-bottom: 5px;
  }
}
@media screen and (min-width: 990px) {
  #main-header #logo {
    max-width: inherit;
  }
}
@media screen and (min-width: 1200px) {
  #main-header nav {
    padding-right: 30px;
  }
  #main-header nav > ul > li a {
    padding: 10px 20px;
  }
  #main-header .header-contact {
    padding: 15px 40px;
  }
}
#main-footer .footer-title {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 1.66666667em;
  line-height: 1.5;
  font-weight: 400;
  display: block;
  color: #070707;
}
#main-footer .footer-title:nth-child(1) {
  margin-top: 0;
}
#main-footer .wrap-maps #leaflet-maps {
  min-height: 450px;
  z-index: 1;
}
#main-footer .wrap-maps .alpixel-coordinates {
  padding: 50px 20px;
  text-align: center;
  color: inherit;
}
#main-footer .wrap-maps .alpixel-coordinates a,
#main-footer .wrap-maps .alpixel-coordinates strong {
  color: #B85500;
}
#main-footer .wrap-maps .alpixel-coordinates ul li {
  display: inline-block;
  padding: 0 20px;
}
#main-footer .wrap-maps .alpixel-coordinates ul li a {
  display: block;
  font-size: 3rem;
}
#main-footer .alpixel-credits {
  padding: 30px 20px;
  background-color: #282828;
  text-align: center;
  font-size: 1.2rem;
}
#main-footer .alpixel-credits ul {
  color: #8F8F8F;
}
#main-footer .alpixel-credits ul li {
  padding: 10px 0 0;
}
#main-footer .alpixel-credits a {
  display: inline-block;
  padding: 10px 0;
  color: #8F8F8F;
  border-bottom: 1px solid currentColor;
}
#main-footer .alpixel-credits a.active {
  color: white;
  border-bottom-color: white;
}
@media screen and (min-width: 990px) {
  #main-footer .wrap-maps {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  #main-footer .wrap-maps #leaflet-maps {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66%;
        -ms-flex: 0 0 66%;
            flex: 0 0 66%;
  }
  #main-footer .wrap-maps .alpixel-coordinates {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
  }
  #main-footer .alpixel-credits ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #main-footer .alpixel-credits ul li {
    padding: 0 10px;
  }
  #main-footer .alpixel-credits ul a {
    padding: 5px 0;
  }
}
/*

*/
.homepage-cycletext {
  position: relative;
  z-index: 5;
  padding: 20px;
  height: 50vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.homepage-cycletext p {
  font-size: 3rem;
  width: 100%;
}
.homepage-cycletext p em {
  display: block;
  font-style: normal;
  text-align: center;
  color: #777;
  font-weight: 300;
  font-size: 1.8rem;
}
.homepage-cycletext p span {
  color: #ff810d;
  text-align: center;
  font-size: 2rem;
  display: none;
}
.homepage-cycletext p span.shown {
  display: block;
}
.go-down {
  display: none;
}
.css-btn-center {
  background-color: white;
  text-align: center;
  padding: 50px 20px 100px;
}
.flex-services {
  background-color: white;
}
.flex-services > div.backstretch {
  min-height: 250px;
}
.flex-services .services {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.flex-services .service-item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  padding: 0;
}
.flex-services .service-item a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.flex-services .service-item a:hover {
  background-color: #f9f9f9;
}
.flex-services .service-item a:hover i {
  color: #ff810d;
}
.flex-services .service-item i {
  color: #adadad;
  font-size: 7rem;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.flex-services .service-item h3 {
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}
.wrap-testinomial {
  padding: 50px 20px;
  background-color: #282828;
  overflow: hidden;
}
.wrap-testinomial > .cc-inside {
  position: relative;
}
.wrap-testinomial h2 {
  font-size: 2.5rem;
  color: white;
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
}
.wrap-testinomial .icon-illu {
  color: #353535;
  font-size: 30rem;
  position: absolute;
  z-index: 0;
  top: -100px;
  left: -100px;
}
.wrap-testinomial .icon-illu i {
  display: block;
}
.wrap-testinomial .main-carousel {
  position: relative;
  z-index: 2;
}
.wrap-testinomial .main-carousel .flickity-viewport {
  -webkit-transition: height 0.2s;
  transition: height 0.2s;
}
.wrap-testinomial .main-carousel .carousel-cell {
  width: 100%;
}
.wrap-testinomial .main-carousel .carousel-cell a {
  display: block;
}
.wrap-testinomial .main-carousel .carousel-cell blockquote {
  padding: 0;
  margin: 0;
  border: 0;
  padding: 0 20px;
  text-align: justify;
  font-style: italic;
  font-weight: 300;
  color: #bfbfbf;
}
.wrap-testinomial .main-carousel .carousel-cell blockquote footer {
  padding-top: 0;
  margin-top: 25px;
  font-size: 1.4rem;
}
.wrap-testinomial .main-carousel .flickity-page-dots .dot {
  background-color: #bbb;
  cursor: pointer;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.wrap-testinomial .main-carousel .flickity-page-dots .dot:hover,
.wrap-testinomial .main-carousel .flickity-page-dots .dot.is-selected {
  background-color: #ff810d;
}
@media screen and (min-width: 768px) {
  .homepage-cycletext {
    height: 100vh;
  }
  .go-down {
    display: block;
    border: 3px solid white;
    background-color: rgba(255, 255, 255, 0.6);
    width: 40px;
    height: 80px;
    border-radius: 40px;
    position: absolute;
    z-index: 6;
    bottom: 30px;
    left: 50%;
    margin-left: -20px;
    padding: 5px;
    text-align: center;
    -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
  }
  .go-down i {
    position: relative;
    top: 40px;
    color: #ff810d;
    background-color: #ff810d;
    border-radius: 100%;
  }
  .homepage-cycletext {
    padding-top: 110px;
  }
  .homepage-cycletext p em {
    font-size: inherit;
  }
  .homepage-cycletext p span {
    font-weight: 600;
    font-size: 5rem;
  }
}
@media screen and (min-width: 990px) {
  .css-all-realisations {
    padding-bottom: 90px;
  }
  .flex-services {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: #f9f9f9;
  }
  .flex-services > div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
  }
  .flex-services .services {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .flex-services .service-item {
    background-color: white;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.2%;
        -ms-flex: 0 0 33.2%;
            flex: 0 0 33.2%;
    min-height: inherit;
    margin-bottom: 0.15%;
  }
  .wrap-testinomial {
    padding: 60px 20px;
  }
  .wrap-testinomial .carousel-cell blockquote {
    padding: 0 70px;
  }
}
@media screen and (min-width: 1200px) {
  .flex-services .service-item a {
    padding: 60px 20px;
  }
  .wrap-testinomial .icon-illu {
    font-size: 30rem;
    top: -150px;
    left: -50px;
  }
}

