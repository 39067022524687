@charset "UTF-8";

@import 'normalize';

// ChuckCSS : import only used on homepage
@import (reference) "../../node_modules/ChuckCSS/chuckcss/settings";
@import (reference) "../../node_modules/ChuckCSS/chuckcss/main/functions";
@import (reference) "../../node_modules/ChuckCSS/chuckcss/main/responsive";
@import "../../node_modules/ChuckCSS/chuckcss/main/base";


// Settings
@import 'settings-override';

// Fonts used on homepage
.titillium() {
  font-family: 'Titillium Web', sans-serif;
  font-display: auto;
}
.open() {
  font-family: 'Open Sans', sans-serif;
  font-display: auto;
}

// Overflow when phone menu is opened
._media-m-up({
  html.opened-menu,
  body.opened-menu {
    overflow-y: auto !important;
  }
});


body {
  background-color: white;
  color: @text-mc;
  .open;

  // Main containers
  .cc-inside {
    ._inside(100%);

    // Screen > 989px
    ._media-m-up({ ._inside(900px); });

    // Screen > 1189px
    ._media-l-up({ ._inside(1100px); });
  }

  ul {
    color:@text-mc;
    ._reset;

    li {
      list-style: none;
    }
  }
}


/* define text selection */
::-moz-selection {
  background: @primary;
  color: white;
}

::selection {
  background: @primary;
  color: white;
}


/* ***************** */
/* *********************************************************************************************** */
/** BELOW, SPECIFIC CHUCKCSS USED ON HOMEPAGE BUT DELETED FROM IMPORT IN TOP PAGE, ONLY FOR OPTIM **/
/* *********************************************************************************************** */
/* ***************** */

/* ***************** */
/** LINKS / BUTTONS **/
/* ***************** */
a {
    text-decoration: none;
    color:@links-tc;

    &:focus,
    &:hover {
        text-decoration: none;
        outline:0;
    }
}

/* Buttons && buttons-like HTML links */
a.btn,
button {
    display:inline-block;
    color:@links-tc;
    padding:@links-btn-pa;
    line-height:@links-btn-lh;
    border:unit(@links-btn-bw,px) solid @gray-li;
    text-align:center;
    font-size: @links-fs;
    vertical-align:middle;
    border-radius:unit(@links-btn-br,px);
    cursor:pointer;
    ._transition;

    &:focus,
    &:active,
    &:visited {
        outline:none;
    }

    /* hover : default, change border-color */
    &:hover {
        border-color:@gray;
    }
}

i {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;

  &.fa-accessible-icon:before {content: "\f368"}
  &.fa-angle-double-left:before {content: "\f100"}
  &.fa-angle-double-right:before {content: "\f101"}
  &.fa-assistive-listening-systems:before {content: "\f2a2"}
  &.fa-circle:before {content: "\f111"}
  &.fa-cloud-upload:before {content: "\f0ee"}
  &.fa-code:before {content: "\f121"}
  &.fa-comment-smile:before {content: "\f4b4"}
  &.fa-drafting-compass:before {content: "\f568"}
  &.fa-envelope:before {content: "\f0e0"}
  &.fa-envelope-open:before {content: "\f2b6"}
  &.fa-graduation-cap:before {content: "\f19d"}
  &.fa-hands-helping:before {content: "\f4c4"}
  &.fa-keyboard:before {content: "\f11c"}
  &.fa-megaphone:before {content: "\f675"}
  &.fa-parking:before {content: "\f540"}
  &.icon-left {margin-right: @icon-mal;}
  &.icon-right {margin-left: @icon-mar;}
}


h1 {
  line-height: 1.5;
  margin: 0;
}
h2 {
    font-size: 1.86666667em;
    line-height: 1.5;
    font-weight: 400;
    color: #070707;
    margin: 0;
}
h3 {
  margin: 0;
  font-size: 1.66666667em;
  line-height: 1.5;
  font-weight: 400;
  color: #070707
}
h4 {
  margin: 0;
  font-size: 1.33333333em;
  line-height: 1.5;
  font-weight: 400;
  color: #070707
}

/* ******** */
/** MODALS **/
/* ******** */

/* ********************* */
/* Modal wrapper */
.modal {
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow:none;
    z-index:@modals-zi;
    position:fixed;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    opacity:0;

    /* Shown modal */
    &.active {
        opacity:1;
        z-index:@modals-zi-active;

        .modal-content {
            opacity:1;
            ._scale(1);
        }

        .modal-close, // for chuckcss version < 3.0.6
        & > [data-close-modal]  // for chuckcss version >= 3.0.6
        {
            ._transition(0.3s, top, ease-out, 0.3s);
            top:8px;


            // Set top to 20px on medium devices
            ._media-s-up({
                top:20px;
            });
        }

        .modal-overlay {
            background-color:rgba(0,0,0,0.85);
        }
    }


    /* Sizes */
    &.modal {
        // only on meduim devices and up
        ._media-s-up({
            &-large {
                .modal-content {
                    width:80%;
                }
            }
        });
    }

    /* Close button */
    & > [data-close-modal] {
        ._reset;
        position:fixed;
        top:-60px;
        left:50%;
        z-index:2;
        background:none;
        border-radius:100%;
        line-height:inherit;
        background-color:transparent;
        width:40px;
        height:40px;
        margin-left:-20px;

        &:after,
        &:before {
            display:inline-block;
            content:' ';
            width:19px;
            height:3px;
            background-color:white;
            border-radius:4px;
            position:absolute;
            top:19px;
            left:10px;
        }
        &:before {
            ._rotate(-45deg);
        }
        &:after {
            ._rotate(45deg);
        }

        &:hover {
            background-color:lighten(@red,25%);
        }
    }

    /* Content */
    &-content {
        width:90%;
        max-height:80vh;
        overflow-y:auto;
        overflow-x:hidden;
        background-color:white;
        z-index:2;
        border-radius:4px;
        opacity:0;
        ._transition;
        ._scale(0.5);

        .modal-body {
            padding:20px;
        }

        ._media-s-up({
            width:50%;
        });
    }

    /* Overlay */
    &-overlay {
        position:absolute;
        background-color:transparent;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        width:100vw;
        height:100vh;
        z-index:1;
    }
}

/*
    == HELPERS ==
*/
.@{css-prefix}-inside{._inside;}
.cc-mat-40 {
    margin-top: 40px
}
.cc-mab-50 {
    margin-bottom: 50px
}
.cc-fs-30 {
    font-size: 3rem
}
.cc-txt-center {
    text-align: center
}


/*
    == COLORS ==
*/
p.cc-green {
  color: #5c7d2b
}

span.cc-bg-green {
  color: #fff;
  background-color: #5c7d2b
}
span.cc-bg-red {
  color: #fff;
  background-color: #c41515
}

h2.cc-bg-gray,
div.cc-bg-gray,
section.cc-bg-gray {
  color: #fff;
  background-color: #f9f9f9
}

a.cc-bg-gray a:not(.btn),article.cc-bg-gray a:not(.btn),aside.cc-bg-gray a:not(.btn),code.cc-bg-gray a:not(.btn),div.cc-bg-gray a:not(.btn),em.cc-bg-gray a:not(.btn),footer.cc-bg-gray a:not(.btn),header.cc-bg-gray a:not(.btn),i.cc-bg-gray a:not(.btn),main.cc-bg-gray a:not(.btn),nav.cc-bg-gray a:not(.btn),p.cc-bg-gray a:not(.btn),section.cc-bg-gray a:not(.btn),small.cc-bg-gray a:not(.btn),span.cc-bg-gray a:not(.btn),strong.cc-bg-gray a:not(.btn),td.cc-bg-gray a:not(.btn),th.cc-bg-gray a:not(.btn) {
  color: #fff
}
span.cc-bg-blue {
  color: #fff;
  background-color: #3498db
}
a.btn.cc-bg-orange {
  color: black;
  background-color: #ff810d;
  border-color: #ff810d
}

strong {
  font-weight: 600;
}

h1, .h1-like {
  .titillium;
  color: #444444;
}

.h2-main-title {
  padding: 50px 20px;
  color: @text-mc;
  letter-spacing: 2px;
  font-weight: 300;
  text-align: center;
  font-size: 2rem;


  // Screen > 767px;
  ._media-s-up({ font-size: 2.8rem; });

  // Screen > 989px;
  ._media-m-up({ padding-left: 0; padding-right: 0; });
}

/*
  - Videos top of pages
*/
.css-video-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;

  .homepage & {
    height: 50vh;

  }


  // Overlay
  .video-overlay {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.8);
  }

  ._media-s-up({
    height: 45vh;

    .homepage & {
      height: 100vh;

    }
   });
}

/*
  - Titles H1 + Intro text on pages
*/
.page-intro {
  padding: 50px 20px;

  h1 {
    font-weight: 600;
    font-size: 2.5rem;
  }

  .intro {
    font-weight: 300;
    font-size: 1.9rem;
    text-align: justify;
  }

  // Screen > 767px;
  ._media-s-up({ padding: 120px 20px 60px; h1 {
    font-size: 3.9rem;
  } .intro {
      text-align: center;
      line-height: 1.8
    } });
}

/*
  - 3 pushes after intro
  * Used in :
    - Homepage
    - Metiers & Valeurs
*/
.pushes {
  padding: 50px 20px;

  div {
    padding: 30px 0;
    text-align: center;

    a {
      display: block;
    }

    h2 {
      margin: 20px 0;
    }

    .push-description {
      font-style: italic;
      color: #999999;
      font-size: 1.4rem;

      strong {
        color: @orange;
      }
    }

    .link-preston {
      display: block;
      width: 150px;
      height: 150px;
      background-color: #93ddea;
      border-radius: 100%;
      margin: 0 auto;
      overflow: hidden;

      img,
      picture {
        position: relative;
        display: block;

        &.hide {
          display: none;
        }
      }
    }
  }


  // Screen > 767px;
  ._media-s-up({ padding: 60px 20px 0; display: flex; div {
    flex: 1;
    padding: 0 30px;
  } });
}


/*
  - Realisations pushes
  * Used in :
    - Homepage
    - Clients
*/
.realisations {
  article {
    min-height: 250px;
    display: flex;
    align-items: stretch;
    margin-bottom: 5px;
    overflow: hidden;

    a {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      text-align: center;
      padding: 20px;
      width: 100%;
      min-height: 100%;
      position: relative;

      .agency & {
        &:after {
          display: none;
        }
      }

      &:after {
        display: block;
        content: ' ';
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        z-index: -1
      }

      h3 {
        flex: 0 0 100%;
        .titillium;

        &:after {
          display: block;
          content: ' ';
          background-color: currentColor;
          height: 1px;
          width: 30%;
          margin: 15px auto 20px;
        }
      }

      h4 {
        flex: 0 0 100%;
        color: #737373;
        font-weight: 300;
        font-size: 1.5rem;
      }
    }
  }

  ._media-s-up({ article {
    min-height: 350px;
  } });

  // Screen > 989px;
  ._media-m-up({ display: flex; flex-wrap: wrap; .realisation-item {
    flex: 0 0 50.00%;


    article {
      margin: 0.50%;
      min-height: 300px;

      &:hover {
        a {
          opacity: 1;
          ._translate(0px, 0px, 0px);
        }

        .backstretch img {
          ._filter(grayscale(0));
        }
      }

      a {
        opacity: 0;
        ._translate(0px, 250px, 0px);
        ._transition(0.35s);
        transition-timing-function: cubic-bezier(.45, .38, .34, .83);
      }

      .backstretch img {
        ._transition(0.2s, filter);
        ._filter(grayscale(0.8));
      }
    }

    /*
        C'est joli et ça me sert pour l'inté, donc on ne supprime pas merki :)

        +-----------+-----+-----+
        |           | 2   | 3   |
        +     1     +-----+-----+
        |           |     4     |
        +-----------+-----+-----+
        |     5     | 6   | 7   |
        +-----+-----+-----+-----+
        | 8   | 9   |           |
        +-----+-----+    11     +
        |    10     |           |
        +-----+-----+-----+-----+
        |    12     | 13  | 14  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 16  | 17  |
        +    15     +-----+-----+
        |           |     18    |
        +-----------+-----+-----+
        |    19     | 20  | 21  |
        +-----+-----+-----+-----+
        | 22  | 23  |           |
        +-----+-----+    25     +
        |    24     |           |
        +-----+-----+-----+-----+
        |    26     | 27  | 28  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 30  | 31  |
        +    29     +-----+-----+
        |           |     32    |
        +-----------+-----+-----+
        |    33     | 34  | 35  |
        +-----+-----+-----+-----+
        | 36  | 37  |           |
        +-----+-----+    39     +
        |    38     |           |
        +-----+-----+-----+-----+
        |    40     | 41  | 42  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 44  | 45  |
        +    43     +-----+-----+
        |           |     46    |
        +-----------+-----+-----+
        |    47     | 48  | 49  |
        +-----+-----+-----+-----+
        | 50  | 51  |           |
        +-----+-----+    53     +
        |    52     |           |
        +-----+-----+-----+-----+
        |    54     | 55  | 56  |
        +-----------+-----+-----+
    */

    /* Big Images */

    &.item-53, &.item-43, &.item-39, &.item-29, &.item-25, &.item-15, &.item-11,
    &.item-1 {
      display: flex;
      align-items: stretch;

      article {
        width: 99.00%;
      }
    }

    /* Small : 50% width */

    &.item-55, &.item-50, &.item-48, &.item-44, &.item-41, &.item-36, &.item-34, &.item-30, &.item-27, &.item-22, &.item-20, &.item-16, &.item-13, &.item-8, &.item-6,
    &.item-2 {
      display: flex;
      flex-wrap: wrap;

      article {
        flex: 0 0 49.00%;

        &:nth-child(3) {
          flex: 0 0 99.00%;
        }
      }

    }
  } });
}

/* ********* */
/** IMPORTS **/
/* ********* */

/* Import Helpers files */
@import 'helpers/hamburger';
@import 'helpers/modals';

/* Import libraries plugins files */
@import "libraries/flickity";
@import "libraries/wow";
@import "libraries/leaflet";

/* Import Website styles */
@import 'website/header-footer';


// HOMEPAGE
/*

*/

// Cycle Text
.homepage-cycletext {
  position: relative;
  z-index: 5;
  padding: 20px;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 3rem;
    width: 100%;

    em {
      display: block;
      font-style: normal;
      text-align: center;
      color: #777;
      font-weight: 300;
      font-size: 1.8rem;
    }

    span {
      color: @orange;
      text-align: center;
      font-size: 2rem;
      display: none;

      &.shown {
        display: block;
      }
    }
  }
}

// Go down
.go-down {
  display: none;
}

// Link "Tous nos clients"
.css-btn-center {
  background-color: white;
  text-align: center;
  padding: 50px 20px 100px;
}


// Services
.flex-services {
  background-color: white;

  & > div.backstretch {
    min-height: 250px;
  }

  .services {
    display: flex;
    flex-wrap: wrap;
  }

  .service-item {
    flex: 0 0 50.0%;
    padding: 0;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 30px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ._transition;

      &:hover {
        background-color: @gray;

        i {
          color: @orange;
        }
      }
    }

    i {
      color: darken(@gray, 30%);
      font-size: 7rem;
      ._transition;
    }

    h3 {
      margin-top: 15px;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 600;
      text-align: center;
    }
  }
}


// Testimonial
.wrap-testinomial {
  padding: 50px 20px;
  background-color: #282828;
  overflow: hidden;

  & > .cc-inside {
    position: relative;
  }

  h2 {
    font-size: 2.5rem;
    color: white;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
  }

  .icon-illu {
    color: lighten(#282828, 5%);
    font-size: 30rem;
    position: absolute;
    z-index: 0;
    top: -100px;
    left: -100px;

    i {
      display: block;
    }
  }

  .main-carousel {
    position: relative;
    z-index: 2;

    // Adaptative height = animation
    .flickity-viewport {
      transition: height 0.2s;
    }

    .carousel-cell {
      width: 100%;

      a {
        display: block;
      }

      blockquote {
        ._reset;
        padding: 0 20px;
        text-align: justify;
        font-style: italic;
        font-weight: 300;
        color: darken(white, 25%);

        footer {
          padding-top: 0;
          margin-top: 25px;
          font-size: 1.4rem;
        }
      }
    }


    .flickity-page-dots {
      .dot {
        background-color: #bbb;
        cursor: pointer;
        ._transition;

        &:hover,
        &.is-selected {
          background-color: @orange;

        }
      }
    }
  }
}


// Screen > 767px;
._media-s-up({ // Video + slider = 100vh height
  .homepage-cycletext {
    height: 100vh;
  }
  .go-down {
      display: block;
      border: 3px solid white;
      background-color: rgba(255, 255, 255, 0.6);
      width: 40px;
      height: 80px;
      border-radius: 40px;
      position: absolute;
      z-index: 6;
      bottom: 30px;
      left: 50%;
      margin-left: -20px;
      padding: 5px;
      text-align: center;
      box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .5);

      i {
        position: relative;
        top: 40px;
        color: @orange;
        background-color: @primary;
        border-radius: 100%;
      }
  }

  .homepage-cycletext {
    padding-top: 110px;

    p {
      em {
        font-size: inherit;
      }


      span {
        font-weight: 600;
        font-size: 5rem;
      }
    }
  }
});


// Screen > 989px;
._media-m-up({
  .css-all-realisations {
    padding-bottom: 90px;
  }

  // Services
  .flex-services {
    display: flex;
    flex-wrap: wrap;
    background-color: @gray;

    & > div {
      flex: 0 0 33.00%;
    }

    .services {
      flex: 1;
      justify-content: space-between;
    }

    .service-item {
      background-color: white;
      flex: 0 0 33.2%;
      min-height: inherit;
      margin-bottom: 0.15%;
    }
  }
  // Testimonial
  .wrap-testinomial {
    padding: 60px 20px;

    .carousel-cell {

      blockquote {
        padding: 0 70px;
      }
    }

  }
});

// Screen > 1199px;
._media-l-up({
  // Services
  .flex-services {
    .service-item {
      a {
        padding: 60px 20px;
      }
    }
  } // Testimonial
  .wrap-testinomial {
    .icon-illu {
      font-size: 30rem;

      top: -150px;
      left: -50px;
    }
  }
});

